import React, { useCallback } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import type { GlobalData, PageContentProps } from './template/types';
import type { ComparativePlanData } from './template/section/comparative-plan';
import type { DefaultSectionData } from './template/section/default';
import type { SectionItemData } from './template/section/section-item';
import type { DownloadListingSectionData } from './template/section/download-listing';
import type { MainListingSectionData } from './template/section/main-listing';
import type { BrandCornerData } from './template/section/brand-corner';
import type { HomePageClubicData } from './template/section/homepage-clubic';
import type { ProductListingData } from './template/section/product-listing';
import type { ProductListingItemData } from './template/section/product-listing-item';
import { useDatalayer } from '../utils/AnalyticsContext';
import type { LongReadListingSectionData } from './template/section/long-read-listing';
import { useSdkConfig } from '../utils/config';
import type { HomepageData } from './template/section/homepage';

const SectionItem = dynamic(() => import('./template/section/section-item'));
const DefaultSection = dynamic(() => import('./template/section/default'));
const Homepage = dynamic(() => import('./template/section/homepage'));
const HomePageClubic = dynamic(() => import('./template/section/homepage-clubic'));
const ComparativePlanSection = dynamic(() => import('./template/section/comparative-plan'));
const MainListing = dynamic(() => import('./template/section/main-listing'));
const DownloadListing = dynamic(() => import('./template/section/download-listing'));
const BrandCorner = dynamic(() => import('./template/section/brand-corner'));
const ReviewListing = dynamic(() => import('./template/section/review-listing'));
const BestPickListing = dynamic(() => import('./template/section/best-pick-listing'));
const TutorialListing = dynamic(() => import('./template/section/tutorial-listing'));
const BrandListing = dynamic(() => import('./template/section/brand-listing'));
const NewsListing = dynamic(() => import('./template/section/news-listing'));
const LongReadListing = dynamic(() => import('./template/section/long-read-listing'));
const ProductListingItem = dynamic(() => import('./template/section/product-listing-item'));
const ProductListing = dynamic(() => import('./template/section/product-listing'));

export function SectionPageContent({ pageInfo, set0verrideAdUnit, data }: PageContentProps) {
  useDatalayer({
    type: 'section',
  });

  const urlTrim = pageInfo.url.replace(/^\/+|\/+$/g, '');
  const config = useSdkConfig();

  const Rss = useCallback(
    () => (
      <Head>
        <link
          rel="alternate"
          type="application/rss+xml"
          href={`${config.baseUrl}/feed/${urlTrim ? `${urlTrim}/` : ''}rss`}
        />
        <link
          rel="alternate"
          type="application/atom+xml"
          href={`${config.baseUrl}/feed/${urlTrim ? `${urlTrim}/` : ''}atom`}
        />
        <link
          rel="alternate"
          type="application/json"
          href={`${config.baseUrl}/feed/${urlTrim ? `${urlTrim}/` : ''}json`}
        />
      </Head>
    ),
    [urlTrim, config.baseUrl],
  );

  switch (pageInfo.dataLayout) {
    case 'comparative_plan':
      return (
        <>
          <Rss />
          <ComparativePlanSection
            pageInfo={pageInfo}
            data={data as ComparativePlanData & GlobalData}
            breadcrumb={pageInfo.breadcrumb}
          />
        </>
      );
    case 'brand_listing':
      return (
        <>
          <Rss />
          <BrandListing
            section={pageInfo.section}
            data={data as MainListingSectionData & GlobalData}
            breadcrumb={pageInfo.breadcrumb}
            pageInfo={pageInfo}
          />
        </>
      );
    case 'main_listing':
      return (
        <>
          <Rss />
          <MainListing
            section={pageInfo.section}
            data={data as MainListingSectionData & GlobalData}
            breadcrumb={pageInfo.breadcrumb}
            pageInfo={pageInfo}
          />
        </>
      );
    case 'review_listing':
      return (
        <>
          <Rss />
          <ReviewListing
            section={pageInfo.section}
            data={data as MainListingSectionData & GlobalData}
            breadcrumb={pageInfo.breadcrumb}
            pageInfo={pageInfo}
          />
        </>
      );
    case 'news_listing':
      return (
        <>
          <Rss />
          <NewsListing
            section={pageInfo.section}
            data={data as MainListingSectionData & GlobalData}
            breadcrumb={pageInfo.breadcrumb}
            pageInfo={pageInfo}
          />
        </>
      );
    case 'tutorial_listing':
      return (
        <>
          <Rss />
          <TutorialListing
            section={pageInfo.section}
            data={data as MainListingSectionData & GlobalData}
            breadcrumb={pageInfo.breadcrumb}
            pageInfo={pageInfo}
          />
        </>
      );
    case 'best_pick_listing':
      return (
        <>
          <Rss />
          <BestPickListing
            section={pageInfo.section}
            data={data as MainListingSectionData & GlobalData}
            breadcrumb={pageInfo.breadcrumb}
            pageInfo={pageInfo}
          />
        </>
      );
    case 'section_item':
      return (
        <>
          <Rss />
          <SectionItem
            pageInfo={pageInfo}
            data={data as SectionItemData & GlobalData}
            set0verrideAdUnit={set0verrideAdUnit}
          />
        </>
      );
    case 'download_listing':
      return (
        <>
          <Rss />
          <DownloadListing
            pageInfo={pageInfo}
            data={data as DownloadListingSectionData & GlobalData}
            breadcrumb={pageInfo.breadcrumb}
            section={pageInfo.section}
            set0verrideAdUnit={set0verrideAdUnit}
          />
        </>
      );
    case 'brand_corner':
      return (
        <>
          <Rss />
          <BrandCorner
            pageInfo={pageInfo}
            data={data as BrandCornerData & GlobalData}
            breadcrumb={pageInfo.breadcrumb}
            section={pageInfo.section}
          />
        </>
      );
    case 'homepage_clubic':
      return (
        <>
          <Rss />
          <HomePageClubic
            pageInfo={pageInfo}
            section={pageInfo.section}
            data={data as HomePageClubicData & GlobalData}
            breadcrumb={pageInfo.breadcrumb}
          />
        </>
      );
    case 'homepage':
      return (
        <>
          <Rss />
          <Homepage
            pageInfo={pageInfo}
            section={pageInfo.section}
            data={data as HomepageData & GlobalData}
            breadcrumb={pageInfo.breadcrumb}
          />
        </>
      );
    case 'long_read_listing':
      return (
        <>
          <Rss />
          <LongReadListing
            pageInfo={pageInfo}
            section={pageInfo.section}
            data={data as LongReadListingSectionData & GlobalData}
            breadcrumb={pageInfo.breadcrumb}
          />
        </>
      );
    case 'product_listing':
      return (
        <>
          <Rss />
          <ProductListing
            pageInfo={pageInfo}
            data={data as ProductListingData & GlobalData}
            breadcrumb={pageInfo.breadcrumb}
          />
        </>
      );
    case 'product_listing_item':
      return (
        <>
          <Rss />
          <ProductListingItem
            pageInfo={pageInfo}
            data={data as ProductListingItemData & GlobalData}
            breadcrumb={pageInfo.breadcrumb}
          />
        </>
      );
    case 'section_default':
    default:
      return (
        <>
          <Rss />
          <DefaultSection
            section={pageInfo.section}
            data={data as DefaultSectionData & GlobalData}
            breadcrumb={pageInfo.breadcrumb}
            pageInfo={pageInfo}
          />
        </>
      );
  }
}
